html {
  width: 100vw;
  height: 100vh;
  background-color: white;
}
body, #root, .app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

a, a:link, a:visited, a:hover, a:active {
  color: #FCFCFC;
}

.hidden {
  display: none;
}

.text-success {
  color: green;
}

hr {
  width: 100%;
}