.slide {
  border: 11px solid black;
  width: 1632px;
  min-height: 936px;
  font-size: 75px;
  text-align: center;
  display: inline-block;
  font-family: 'ITC Korinna';
  color: #FCFCFC;
  background-color: #050584;
  text-shadow: 7px 7px black;
  letter-spacing: .05em;
  line-height: 1.05em;
  text-transform: uppercase;

  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-font-smoothing: antialiased;

  flex-direction: column;
}

.slide-text {
  width: 60%;
  /* height: 50%; */
}

.slide-header {
  font-size: 40px;
  margin-top: -120px;
  margin-bottom: 120px;
  text-shadow: 4px 4px black;
}