.podiums {
  width: 1676px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.podium {
  width: 180px;
  text-align: center;
  border: 4px solid black;
  background-color: #060075;
  color: #FCFCFC;
  margin: 15px 15px 0 15px;
  user-select: none;
  -webkit-font-smoothing: antialiased;
}
.podium.is-tapping {
  background-color: #ddf7f4;
}
.podium.buzzed-in {
  background-color: #ddf7f4;
}

/* for player client */
/* .podium-buzzer:hover {
  background-color: #ddf7f4;
} */
/* .podium-buzzer.is-clicking {
  background-color: #b7ccca;
} */
.podium-buzzer.has-guessed {
  background-color: #333333;
}
.podium-buzzer.has-guessed:hover {
  background-color: #333333;
}

.score-value {
  background-color: #07008a;
  height: 50px;
  border-bottom: 4px solid black;
  font-family: 'Univers 75 Black';
  font-size: 35px;
  text-shadow: 2px 2px black;

  display: flex;
  justify-content: center;
  align-items: center;
}
.score-value-negative {
  color: #D80637
}
.score-value-text {
  padding-top: 5px;
  text-overflow: clip;
  overflow: hidden;
}

.score-name {
  background-color: #00238b;
  height: 100px;
  border-top: 4px solid black;
  border-bottom: 4px solid black;
  margin-top: 30px;
  margin-bottom: 70px;
  font-size: 45px;
  font-family: Arial;

  display: flex;
  justify-content: center;
  align-items: center;
}

.score-name-text {
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
}
