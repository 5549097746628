html {
  background-color: rgb(226, 234, 235);
}

.controls {
  position: fixed;
  left: 5px;
  bottom: 5px;
}

.dashboard {
  padding-bottom: 5px;
}