@import url(https://fonts.googleapis.com/css2?family=Caveat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dawning+of+a+New+Day&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Give+You+Glory&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hachi+Maru+Pop&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Handlee&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hi+Melody&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kristi&display=swap);
@import url(https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Long+Cang&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nothing+You+Could+Do&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Schoolbell&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Short+Stack&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Butterfly+Kids&display=swap);
.board-tile {
  height: 150px;
  width: calc(150px * 1.78);
  text-align: center;
  border: 3px solid black;
  background-color: #050584;
  text-transform: uppercase;

  display: flex;
  justify-content: center;
  align-items: center;
}

.board-tile-text {
  /* height: 50%; */

  /* margin-top: 20px; */
  display: inline-block;
  color: #E69132;
  /* color: #D79741; */
  font-family: 'Swiss 911 Extra';
  font-size: 104px;
  text-shadow: 6px 7px black;
  letter-spacing: .06em;
}

.board-tile-text-category {
  color: #FCFCFC;
  font-size: 41px;
  text-shadow: none;
  line-height: .89em;
  width: 92%;
}

.board {
  /* display: flex; */
  /* width: calc(1632px + 22px);
  height: calc(936px + 22px); */
}

.row {
  /* width: 100%; */
  /* height: 100px; */
  display: flex;
  /* flex-direction: column; */
  border-left: 8px solid black;
  border-right: 8px solid black;
}
.category-row {
  border-top: 8px solid black;
  border-bottom: 6px solid black;
}
.last-row {
  border-bottom: 8px solid black;
}

.slide {
  border: 11px solid black;
  width: 1632px;
  min-height: 936px;
  font-size: 75px;
  text-align: center;
  display: inline-block;
  font-family: 'ITC Korinna';
  color: #FCFCFC;
  background-color: #050584;
  text-shadow: 7px 7px black;
  letter-spacing: .05em;
  line-height: 1.05em;
  text-transform: uppercase;

  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-font-smoothing: antialiased;

  flex-direction: column;
}

.slide-text {
  width: 60%;
  /* height: 50%; */
}

.slide-header {
  font-size: 40px;
  margin-top: -120px;
  margin-bottom: 120px;
  text-shadow: 4px 4px black;
}

.score-controls-container {
  margin: 5px 30px 0 30px;
  /* height: 70px; */
}
.score-controls {
  height: 0px
}

.podiums {
  width: 1676px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.podium {
  width: 180px;
  text-align: center;
  border: 4px solid black;
  background-color: #060075;
  color: #FCFCFC;
  margin: 15px 15px 0 15px;
  -webkit-user-select: none;
          user-select: none;
  -webkit-font-smoothing: antialiased;
}
.podium.is-tapping {
  background-color: #ddf7f4;
}
.podium.buzzed-in {
  background-color: #ddf7f4;
}

/* for player client */
/* .podium-buzzer:hover {
  background-color: #ddf7f4;
} */
/* .podium-buzzer.is-clicking {
  background-color: #b7ccca;
} */
.podium-buzzer.has-guessed {
  background-color: #333333;
}
.podium-buzzer.has-guessed:hover {
  background-color: #333333;
}

.score-value {
  background-color: #07008a;
  height: 50px;
  border-bottom: 4px solid black;
  font-family: 'Univers 75 Black';
  font-size: 35px;
  text-shadow: 2px 2px black;

  display: flex;
  justify-content: center;
  align-items: center;
}
.score-value-negative {
  color: #D80637
}
.score-value-text {
  padding-top: 5px;
  text-overflow: clip;
  overflow: hidden;
}

.score-name {
  background-color: #00238b;
  height: 100px;
  border-top: 4px solid black;
  border-bottom: 4px solid black;
  margin-top: 30px;
  margin-bottom: 70px;
  font-size: 45px;
  font-family: Arial;

  display: flex;
  justify-content: center;
  align-items: center;
}

.score-name-text {
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
}

.game {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  padding: 3px;
  -webkit-user-select: none;
          user-select: none;

  display: flex;
  align-items: center;
  flex-direction: column;
}

.game-info {
  position: absolute;
  font-family: sans-serif;
  font-size: 30px;
  color: #eee;
  text-shadow: 1px 1px #dedede;
}

.round-controls {
  margin: 25px 10px 0 0;
  text-align: right;
}

.game-timer-button {
  width: 100px;
  height: 40px;
  border-color: #f7f7f7;
  color: darkgrey;
  border-bottom-color: #f7f7f7;
}

.host-lobby {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  padding: 3px;
  -webkit-user-select: none;
          user-select: none;

  display: flex;
  align-items: center;
  flex-direction: column;
}

.host-lobby-code {
  font-size: 140px;
  margin-top: 20px;
}

html {
  background-color: rgb(226, 234, 235);
}

.controls {
  position: fixed;
  left: 5px;
  bottom: 5px;
}

.dashboard {
  padding-bottom: 5px;
}
.player-client {
  text-align: center;
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.host-feed-events {
  text-align: left;
}
.player-lobby {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.player-lobby-podium-label {
  font-style: italic;
  font-size: 15px;
  margin-top: 5px;
}

.player-lobby-input-section {
  display: inline-flex;
}
.player-lobby-input-section input {
  width: 130px;
}


.player-lobby-name {
  margin-top: 2px;
  margin-bottom: 10px;
}

.player-name-chars-left-container {
  position: relative;
}

.player-name-chars-left {
  position: absolute;
  bottom: 10px;
  right: -26px;
  font-size: 18px;
  font-family: sans-serif;
  float: right;
  font-weight: 500;
}

a.host-game-link, a:link.host-game-link, a:visited.host-game-link, a:hover.host-game-link, a:active.host-game-link {
  color: grey;
}

a.changelog-link, a:link.changelog-link, a:visited.changelog-link, a:hover.changelog-link, a:active.changelog-link {
  color: grey;
  font-size: 12px;
}
.buzzer-podium-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buzzer-final-jeopardy {
  margin-top: 100px;
}
.buzzer-final-jeopardy input {
  width: 160px;
  margin-bottom: 2px;
  margin-right: 5px;
}
.buzzer-final-jeopardy button {
  width: 130px;
}

.buzzer-button {
  margin: 10px;
  height: 150px;
  width: 150px;
  font-size: 20px;
  -webkit-user-select: none;
          user-select: none;
  border-radius: 0px;
}

.final-jeopardy-success {
  margin-left: 10px;
  margin-right: -150px;
  text-align: left;
  display: inline-block;
  width: 140px;
}

.markdown-container {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 50px 0;
  color: #24292f;
  background-color: #ffffff;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
}

.markdown-container .octicon {
  display: inline-block;
  fill: currentColor;
  vertical-align: text-bottom;
}

.markdown-container h1:hover .anchor .octicon-link:before,
.markdown-container h2:hover .anchor .octicon-link:before,
.markdown-container h3:hover .anchor .octicon-link:before,
.markdown-container h4:hover .anchor .octicon-link:before,
.markdown-container h5:hover .anchor .octicon-link:before,
.markdown-container h6:hover .anchor .octicon-link:before {
  width: 16px;
  height: 16px;
  content: ' ';
  display: inline-block;
  background-color: currentColor;
  -webkit-mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' version='1.1' aria-hidden='true'><path fill-rule='evenodd' d='M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z'></path></svg>");
  mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' version='1.1' aria-hidden='true'><path fill-rule='evenodd' d='M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z'></path></svg>");
}

.markdown-container details,
.markdown-container figcaption,
.markdown-container figure {
  display: block;
}

.markdown-container summary {
  display: list-item;
}

.markdown-container [hidden] {
  display: none !important;
}

.markdown-container a {
  background-color: transparent;
  color: #0969da;
  text-decoration: none;
}

.markdown-container abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}

.markdown-container b,
.markdown-container strong {
  font-weight: 600;
}

.markdown-container dfn {
  font-style: italic;
}

.markdown-container h1 {
  margin: .67em 0;
  font-weight: 600;
  padding-bottom: .3em;
  font-size: 2em;
  border-bottom: 1px solid hsla(210,18%,87%,1);
}

.markdown-container mark {
  background-color: #fff8c5;
  color: #24292f;
}

.markdown-container small {
  font-size: 90%;
}

.markdown-container sub,
.markdown-container sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

.markdown-container sub {
  bottom: -0.25em;
}

.markdown-container sup {
  top: -0.5em;
}

.markdown-container img {
  border-style: none;
  max-width: 100%;
  box-sizing: content-box;
  background-color: #ffffff;
}

.markdown-container code,
.markdown-container kbd,
.markdown-container pre,
.markdown-container samp {
  font-family: monospace;
  font-size: 1em;
}

.markdown-container figure {
  margin: 1em 40px;
}

.markdown-container hr {
  box-sizing: content-box;
  overflow: hidden;
  background: transparent;
  border-bottom: 1px solid hsla(210,18%,87%,1);
  height: .25em;
  padding: 0;
  margin: 24px 0;
  background-color: #d0d7de;
  border: 0;
}

.markdown-container input {
  font: inherit;
  margin: 0;
  overflow: visible;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.markdown-container [type=button],
.markdown-container [type=reset],
.markdown-container [type=submit] {
  -webkit-appearance: button;
}

.markdown-container [type=checkbox],
.markdown-container [type=radio] {
  box-sizing: border-box;
  padding: 0;
}

.markdown-container [type=number]::-webkit-inner-spin-button,
.markdown-container [type=number]::-webkit-outer-spin-button {
  height: auto;
}

.markdown-container [type=search]::-webkit-search-cancel-button,
.markdown-container [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.markdown-container ::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

.markdown-container ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.markdown-container a:hover {
  text-decoration: underline;
}

.markdown-container ::-webkit-input-placeholder {
  color: #6e7781;
  opacity: 1;
}

.markdown-container ::placeholder {
  color: #6e7781;
  opacity: 1;
}

.markdown-container hr::before {
  display: table;
  content: "";
}

.markdown-container hr::after {
  display: table;
  clear: both;
  content: "";
}

.markdown-container table {
  border-spacing: 0;
  border-collapse: collapse;
  display: block;
  width: -webkit-max-content;
  width: max-content;
  max-width: 100%;
  overflow: auto;
}

.markdown-container td,
.markdown-container th {
  padding: 0;
}

.markdown-container details summary {
  cursor: pointer;
}

.markdown-container details:not([open])>*:not(summary) {
  display: none !important;
}

.markdown-container a:focus,
.markdown-container [role=button]:focus,
.markdown-container input[type=radio]:focus,
.markdown-container input[type=checkbox]:focus {
  outline: 2px solid #0969da;
  outline-offset: -2px;
  box-shadow: none;
}

.markdown-container a:focus:not(:focus-visible),
.markdown-container [role=button]:focus:not(:focus-visible),
.markdown-container input[type=radio]:focus:not(:focus-visible),
.markdown-container input[type=checkbox]:focus:not(:focus-visible) {
  outline: solid 1px transparent;
}

.markdown-container a:focus-visible,
.markdown-container [role=button]:focus-visible,
.markdown-container input[type=radio]:focus-visible,
.markdown-container input[type=checkbox]:focus-visible {
  outline: 2px solid #0969da;
  outline-offset: -2px;
  box-shadow: none;
}

.markdown-container a:not([class]):focus,
.markdown-container a:not([class]):focus-visible,
.markdown-container input[type=radio]:focus,
.markdown-container input[type=radio]:focus-visible,
.markdown-container input[type=checkbox]:focus,
.markdown-container input[type=checkbox]:focus-visible {
  outline-offset: 0;
}

.markdown-container kbd {
  display: inline-block;
  padding: 3px 5px;
  font: 11px ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace;
  line-height: 10px;
  color: #24292f;
  vertical-align: middle;
  background-color: #f6f8fa;
  border: solid 1px rgba(175,184,193,0.2);
  border-bottom-color: rgba(175,184,193,0.2);
  border-radius: 6px;
  box-shadow: inset 0 -1px 0 rgba(175,184,193,0.2);
}

.markdown-container h1,
.markdown-container h2,
.markdown-container h3,
.markdown-container h4,
.markdown-container h5,
.markdown-container h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
}

.markdown-container h2 {
  font-weight: 600;
  padding-bottom: .3em;
  font-size: 1.5em;
  border-bottom: 1px solid hsla(210,18%,87%,1);
}

.markdown-container h3 {
  font-weight: 600;
  font-size: 1.25em;
}

.markdown-container h4 {
  font-weight: 600;
  font-size: 1em;
}

.markdown-container h5 {
  font-weight: 600;
  font-size: .875em;
}

.markdown-container h6 {
  font-weight: 600;
  font-size: .85em;
  color: #57606a;
}

.markdown-container p {
  margin-top: 0;
  margin-bottom: 10px;
}

.markdown-container blockquote {
  margin: 0;
  padding: 0 1em;
  color: #57606a;
  border-left: .25em solid #d0d7de;
}

.markdown-container ul,
.markdown-container ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 2em;
}

.markdown-container ol ol,
.markdown-container ul ol {
  list-style-type: lower-roman;
}

.markdown-container ul ul ol,
.markdown-container ul ol ol,
.markdown-container ol ul ol,
.markdown-container ol ol ol {
  list-style-type: lower-alpha;
}

.markdown-container dd {
  margin-left: 0;
}

.markdown-container tt,
.markdown-container code,
.markdown-container samp {
  font-family: ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace;
  font-size: 12px;
}

.markdown-container pre {
  margin-top: 0;
  margin-bottom: 0;
  font-family: ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace;
  font-size: 12px;
  word-wrap: normal;
}

.markdown-container .octicon {
  display: inline-block;
  overflow: visible !important;
  vertical-align: text-bottom;
  fill: currentColor;
}

.markdown-container input::-webkit-outer-spin-button,
.markdown-container input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
  appearance: none;
}

.markdown-container::before {
  display: table;
  content: "";
}

.markdown-container::after {
  display: table;
  clear: both;
  content: "";
}

.markdown-container>*:first-child {
  margin-top: 0 !important;
}

.markdown-container>*:last-child {
  margin-bottom: 0 !important;
}

.markdown-container a:not([href]) {
  color: inherit;
  text-decoration: none;
}

.markdown-container .absent {
  color: #cf222e;
}

.markdown-container .anchor {
  float: left;
  padding-right: 4px;
  margin-left: -20px;
  line-height: 1;
}

.markdown-container .anchor:focus {
  outline: none;
}

.markdown-container p,
.markdown-container blockquote,
.markdown-container ul,
.markdown-container ol,
.markdown-container dl,
.markdown-container table,
.markdown-container pre,
.markdown-container details {
  margin-top: 0;
  margin-bottom: 16px;
}

.markdown-container blockquote>:first-child {
  margin-top: 0;
}

.markdown-container blockquote>:last-child {
  margin-bottom: 0;
}

.markdown-container h1 .octicon-link,
.markdown-container h2 .octicon-link,
.markdown-container h3 .octicon-link,
.markdown-container h4 .octicon-link,
.markdown-container h5 .octicon-link,
.markdown-container h6 .octicon-link {
  color: #24292f;
  vertical-align: middle;
  visibility: hidden;
}

.markdown-container h1:hover .anchor,
.markdown-container h2:hover .anchor,
.markdown-container h3:hover .anchor,
.markdown-container h4:hover .anchor,
.markdown-container h5:hover .anchor,
.markdown-container h6:hover .anchor {
  text-decoration: none;
}

.markdown-container h1:hover .anchor .octicon-link,
.markdown-container h2:hover .anchor .octicon-link,
.markdown-container h3:hover .anchor .octicon-link,
.markdown-container h4:hover .anchor .octicon-link,
.markdown-container h5:hover .anchor .octicon-link,
.markdown-container h6:hover .anchor .octicon-link {
  visibility: visible;
}

.markdown-container h1 tt,
.markdown-container h1 code,
.markdown-container h2 tt,
.markdown-container h2 code,
.markdown-container h3 tt,
.markdown-container h3 code,
.markdown-container h4 tt,
.markdown-container h4 code,
.markdown-container h5 tt,
.markdown-container h5 code,
.markdown-container h6 tt,
.markdown-container h6 code {
  padding: 0 .2em;
  font-size: inherit;
}

.markdown-container summary h1,
.markdown-container summary h2,
.markdown-container summary h3,
.markdown-container summary h4,
.markdown-container summary h5,
.markdown-container summary h6 {
  display: inline-block;
}

.markdown-container summary h1 .anchor,
.markdown-container summary h2 .anchor,
.markdown-container summary h3 .anchor,
.markdown-container summary h4 .anchor,
.markdown-container summary h5 .anchor,
.markdown-container summary h6 .anchor {
  margin-left: -40px;
}

.markdown-container summary h1,
.markdown-container summary h2 {
  padding-bottom: 0;
  border-bottom: 0;
}

.markdown-container ul.no-list,
.markdown-container ol.no-list {
  padding: 0;
  list-style-type: none;
}

.markdown-container ol[type=a] {
  list-style-type: lower-alpha;
}

.markdown-container ol[type=A] {
  list-style-type: upper-alpha;
}

.markdown-container ol[type=i] {
  list-style-type: lower-roman;
}

.markdown-container ol[type=I] {
  list-style-type: upper-roman;
}

.markdown-container ol[type="1"] {
  list-style-type: decimal;
}

.markdown-container div>ol:not([type]) {
  list-style-type: decimal;
}

.markdown-container ul ul,
.markdown-container ul ol,
.markdown-container ol ol,
.markdown-container ol ul {
  margin-top: 0;
  margin-bottom: 0;
}

.markdown-container li>p {
  margin-top: 16px;
}

.markdown-container li+li {
  margin-top: .25em;
}

.markdown-container dl {
  padding: 0;
}

.markdown-container dl dt {
  padding: 0;
  margin-top: 16px;
  font-size: 1em;
  font-style: italic;
  font-weight: 600;
}

.markdown-container dl dd {
  padding: 0 16px;
  margin-bottom: 16px;
}

.markdown-container table th {
  font-weight: 600;
}

.markdown-container table th,
.markdown-container table td {
  padding: 6px 13px;
  border: 1px solid #d0d7de;
}

.markdown-container table tr {
  background-color: #ffffff;
  border-top: 1px solid hsla(210,18%,87%,1);
}

.markdown-container table tr:nth-child(2n) {
  background-color: #f6f8fa;
}

.markdown-container table img {
  background-color: transparent;
}

.markdown-container img[align=right] {
  padding-left: 20px;
}

.markdown-container img[align=left] {
  padding-right: 20px;
}

.markdown-container .emoji {
  max-width: none;
  vertical-align: text-top;
  background-color: transparent;
}

.markdown-container span.frame {
  display: block;
  overflow: hidden;
}

.markdown-container span.frame>span {
  display: block;
  float: left;
  width: auto;
  padding: 7px;
  margin: 13px 0 0;
  overflow: hidden;
  border: 1px solid #d0d7de;
}

.markdown-container span.frame span img {
  display: block;
  float: left;
}

.markdown-container span.frame span span {
  display: block;
  padding: 5px 0 0;
  clear: both;
  color: #24292f;
}

.markdown-container span.align-center {
  display: block;
  overflow: hidden;
  clear: both;
}

.markdown-container span.align-center>span {
  display: block;
  margin: 13px auto 0;
  overflow: hidden;
  text-align: center;
}

.markdown-container span.align-center span img {
  margin: 0 auto;
  text-align: center;
}

.markdown-container span.align-right {
  display: block;
  overflow: hidden;
  clear: both;
}

.markdown-container span.align-right>span {
  display: block;
  margin: 13px 0 0;
  overflow: hidden;
  text-align: right;
}

.markdown-container span.align-right span img {
  margin: 0;
  text-align: right;
}

.markdown-container span.float-left {
  display: block;
  float: left;
  margin-right: 13px;
  overflow: hidden;
}

.markdown-container span.float-left span {
  margin: 13px 0 0;
}

.markdown-container span.float-right {
  display: block;
  float: right;
  margin-left: 13px;
  overflow: hidden;
}

.markdown-container span.float-right>span {
  display: block;
  margin: 13px auto 0;
  overflow: hidden;
  text-align: right;
}

.markdown-container code,
.markdown-container tt {
  padding: .2em .4em;
  margin: 0;
  font-size: 85%;
  white-space: break-spaces;
  background-color: rgba(175,184,193,0.2);
  border-radius: 6px;
}

.markdown-container code br,
.markdown-container tt br {
  display: none;
}

.markdown-container del code {
  text-decoration: inherit;
}

.markdown-container samp {
  font-size: 85%;
}

.markdown-container pre code {
  font-size: 100%;
}

.markdown-container pre>code {
  padding: 0;
  margin: 0;
  word-break: normal;
  white-space: pre;
  background: transparent;
  border: 0;
}

.markdown-container .highlight {
  margin-bottom: 16px;
}

.markdown-container .highlight pre {
  margin-bottom: 0;
  word-break: normal;
}

.markdown-container .highlight pre,
.markdown-container pre {
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f6f8fa;
  border-radius: 6px;
}

.markdown-container pre code,
.markdown-container pre tt {
  display: inline;
  max-width: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  line-height: inherit;
  word-wrap: normal;
  background-color: transparent;
  border: 0;
}

.markdown-container .csv-data td,
.markdown-container .csv-data th {
  padding: 5px;
  overflow: hidden;
  font-size: 12px;
  line-height: 1;
  text-align: left;
  white-space: nowrap;
}

.markdown-container .csv-data .blob-num {
  padding: 10px 8px 9px;
  text-align: right;
  background: #ffffff;
  border: 0;
}

.markdown-container .csv-data tr {
  border-top: 0;
}

.markdown-container .csv-data th {
  font-weight: 600;
  background: #f6f8fa;
  border-top: 0;
}

.markdown-container [data-footnote-ref]::before {
  content: "[";
}

.markdown-container [data-footnote-ref]::after {
  content: "]";
}

.markdown-container .footnotes {
  font-size: 12px;
  color: #57606a;
  border-top: 1px solid #d0d7de;
}

.markdown-container .footnotes ol {
  padding-left: 16px;
}

.markdown-container .footnotes ol ul {
  display: inline-block;
  padding-left: 16px;
  margin-top: 16px;
}

.markdown-container .footnotes li {
  position: relative;
}

.markdown-container .footnotes li:target::before {
  position: absolute;
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -24px;
  pointer-events: none;
  content: "";
  border: 2px solid #0969da;
  border-radius: 6px;
}

.markdown-container .footnotes li:target {
  color: #24292f;
}

.markdown-container .footnotes .data-footnote-backref g-emoji {
  font-family: monospace;
}

.markdown-container .pl-c {
  color: #6e7781;
}

.markdown-container .pl-c1,
.markdown-container .pl-s .pl-v {
  color: #0550ae;
}

.markdown-container .pl-e,
.markdown-container .pl-en {
  color: #8250df;
}

.markdown-container .pl-smi,
.markdown-container .pl-s .pl-s1 {
  color: #24292f;
}

.markdown-container .pl-ent {
  color: #116329;
}

.markdown-container .pl-k {
  color: #cf222e;
}

.markdown-container .pl-s,
.markdown-container .pl-pds,
.markdown-container .pl-s .pl-pse .pl-s1,
.markdown-container .pl-sr,
.markdown-container .pl-sr .pl-cce,
.markdown-container .pl-sr .pl-sre,
.markdown-container .pl-sr .pl-sra {
  color: #0a3069;
}

.markdown-container .pl-v,
.markdown-container .pl-smw {
  color: #953800;
}

.markdown-container .pl-bu {
  color: #82071e;
}

.markdown-container .pl-ii {
  color: #f6f8fa;
  background-color: #82071e;
}

.markdown-container .pl-c2 {
  color: #f6f8fa;
  background-color: #cf222e;
}

.markdown-container .pl-sr .pl-cce {
  font-weight: bold;
  color: #116329;
}

.markdown-container .pl-ml {
  color: #3b2300;
}

.markdown-container .pl-mh,
.markdown-container .pl-mh .pl-en,
.markdown-container .pl-ms {
  font-weight: bold;
  color: #0550ae;
}

.markdown-container .pl-mi {
  font-style: italic;
  color: #24292f;
}

.markdown-container .pl-mb {
  font-weight: bold;
  color: #24292f;
}

.markdown-container .pl-md {
  color: #82071e;
  background-color: #ffebe9;
}

.markdown-container .pl-mi1 {
  color: #116329;
  background-color: #dafbe1;
}

.markdown-container .pl-mc {
  color: #953800;
  background-color: #ffd8b5;
}

.markdown-container .pl-mi2 {
  color: #eaeef2;
  background-color: #0550ae;
}

.markdown-container .pl-mdr {
  font-weight: bold;
  color: #8250df;
}

.markdown-container .pl-ba {
  color: #57606a;
}

.markdown-container .pl-sg {
  color: #8c959f;
}

.markdown-container .pl-corl {
  text-decoration: underline;
  color: #0a3069;
}

.markdown-container g-emoji {
  display: inline-block;
  min-width: 1ch;
  font-family: "Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 1em;
  font-style: normal !important;
  font-weight: 400;
  line-height: 1;
  vertical-align: -0.075em;
}

.markdown-container g-emoji img {
  width: 1em;
  height: 1em;
}

.markdown-container .task-list-item {
  list-style-type: none;
}

.markdown-container .task-list-item label {
  font-weight: 400;
}

.markdown-container .task-list-item.enabled label {
  cursor: pointer;
}

.markdown-container .task-list-item+.task-list-item {
  margin-top: 4px;
}

.markdown-container .task-list-item .handle {
  display: none;
}

.markdown-container .task-list-item-checkbox {
  margin: 0 .2em .25em -1.4em;
  vertical-align: middle;
}

.markdown-container .contains-task-list:dir(rtl) .task-list-item-checkbox {
  margin: 0 -1.6em .25em .2em;
}

.markdown-container .contains-task-list {
  position: relative;
}

.markdown-container .contains-task-list:hover .task-list-item-convert-container,
.markdown-container .contains-task-list:focus-within .task-list-item-convert-container {
  display: block;
  width: auto;
  height: 24px;
  overflow: visible;
  clip: auto;
}

.markdown-container ::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(50%);
          filter: invert(50%);
}
html {
  width: 100vw;
  height: 100vh;
  background-color: white;
}
body, #root, .app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

a, a:link, a:visited, a:hover, a:active {
  color: #FCFCFC;
}

.hidden {
  display: none;
}

.text-success {
  color: green;
}

hr {
  width: 100%;
}
/* Questions */
@font-face {
  font-family: 'ITC Korinna';
  src: url(/static/media/itc-korinna-bold.3564d2de.otf) format('opentype')
}

/* Board */
@font-face {
  font-family: 'Swiss 911 Extra';
  src: url(/static/media/swiss-911-extra-compr9c22f.e2bb4a67.ttf) format('truetype')
}
@font-face {
  font-family: 'Swiss 911';
  src: url(/static/media/swiss-911-compressed.6e41ffde.otf) format('opentype')
}

/* Scores */
@font-face {
  font-family: 'Univers 75 Black';
  src: url(/static/media/univers-75-black.27ab032e.ttf) format('truetype')
}

.font-itc-korinna { font-family: 'ITC Korinna' }
.font-swiss-911-extra { font-family: 'Swiss 911 Extra' }
.font-swiss-911 { font-family: 'Swiss 911' }
.font-univers-75-black { font-family: 'Univers 75 Black' }

/* Player names */

.player-font-0 { font-family: 'Caveat'; }
.player-font-1 { font-family: 'Shadows Into Light'; }
.player-font-2 { font-family: 'Cedarville Cursive'; }
.player-font-3 { font-family: 'Amatic SC'; }
.player-font-4 { font-family: 'Architects Daughter'; }
.player-font-5 { font-family: 'Dawning of a New Day'; }
.player-font-6 { font-family: 'Give You Glory'; margin-top: 10px; }
.player-font-7 { font-family: 'Gloria Hallelujah'; }
.player-font-8 { font-family: 'Hachi Maru Pop'; margin-top: -12px; }
.player-font-9 { font-family: 'Handlee'; margin-top: 8px; }
.player-font-10 { font-family: 'Hi Melody'; margin-top: 12px; }
.player-font-11 { font-family: 'Indie Flower'; margin-top: 8px; }
.player-font-12 { font-family: 'Kristi'; margin-top: 2px; }
.player-font-13 { font-family: 'La Belle Aurore'; margin-top: 20px; }
.player-font-14 { font-family: 'Long Cang'; }
.player-font-15 { font-family: 'Nothing You Could Do'; margin-top: 10px; }
.player-font-16 { font-family: 'Reenie Beanie'; margin-top: 5px; }
.player-font-17 { font-family: 'Schoolbell'; margin-top: 5px; }
.player-font-18 { font-family: 'Short Stack'; margin-top: 4px; }
.player-font-19 { font-family: 'Butterfly Kids'; }

