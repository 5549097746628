.game {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  padding: 3px;
  user-select: none;

  display: flex;
  align-items: center;
  flex-direction: column;
}

.game-info {
  position: absolute;
  font-family: sans-serif;
  font-size: 30px;
  color: #eee;
  text-shadow: 1px 1px #dedede;
}

.round-controls {
  margin: 25px 10px 0 0;
  text-align: right;
}

.game-timer-button {
  width: 100px;
  height: 40px;
  border-color: #f7f7f7;
  color: darkgrey;
  border-bottom-color: #f7f7f7;
}
