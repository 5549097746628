.board {
  /* display: flex; */
  /* width: calc(1632px + 22px);
  height: calc(936px + 22px); */
}

.row {
  /* width: 100%; */
  /* height: 100px; */
  display: flex;
  /* flex-direction: column; */
  border-left: 8px solid black;
  border-right: 8px solid black;
}
.category-row {
  border-top: 8px solid black;
  border-bottom: 6px solid black;
}
.last-row {
  border-bottom: 8px solid black;
}
