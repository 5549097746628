.buzzer-podium-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buzzer-final-jeopardy {
  margin-top: 100px;
}
.buzzer-final-jeopardy input {
  width: 160px;
  margin-bottom: 2px;
  margin-right: 5px;
}
.buzzer-final-jeopardy button {
  width: 130px;
}

.buzzer-button {
  margin: 10px;
  height: 150px;
  width: 150px;
  font-size: 20px;
  user-select: none;
  border-radius: 0px;
}

.final-jeopardy-success {
  margin-left: 10px;
  margin-right: -150px;
  text-align: left;
  display: inline-block;
  width: 140px;
}
