.player-lobby {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.player-lobby-podium-label {
  font-style: italic;
  font-size: 15px;
  margin-top: 5px;
}

.player-lobby-input-section {
  display: inline-flex;
}
.player-lobby-input-section input {
  width: 130px;
}


.player-lobby-name {
  margin-top: 2px;
  margin-bottom: 10px;
}

.player-name-chars-left-container {
  position: relative;
}

.player-name-chars-left {
  position: absolute;
  bottom: 10px;
  right: -26px;
  font-size: 18px;
  font-family: sans-serif;
  float: right;
  font-weight: 500;
}

a.host-game-link, a:link.host-game-link, a:visited.host-game-link, a:hover.host-game-link, a:active.host-game-link {
  color: grey;
}

a.changelog-link, a:link.changelog-link, a:visited.changelog-link, a:hover.changelog-link, a:active.changelog-link {
  color: grey;
  font-size: 12px;
}