.host-lobby {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  padding: 3px;
  user-select: none;

  display: flex;
  align-items: center;
  flex-direction: column;
}

.host-lobby-code {
  font-size: 140px;
  margin-top: 20px;
}
