.board-tile {
  height: 150px;
  width: calc(150px * 1.78);
  text-align: center;
  border: 3px solid black;
  background-color: #050584;
  text-transform: uppercase;

  display: flex;
  justify-content: center;
  align-items: center;
}

.board-tile-text {
  /* height: 50%; */

  /* margin-top: 20px; */
  display: inline-block;
  color: #E69132;
  /* color: #D79741; */
  font-family: 'Swiss 911 Extra';
  font-size: 104px;
  text-shadow: 6px 7px black;
  letter-spacing: .06em;
}

.board-tile-text-category {
  color: #FCFCFC;
  font-size: 41px;
  text-shadow: none;
  line-height: .89em;
  width: 92%;
}
