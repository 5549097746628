/* Questions */
@font-face {
  font-family: 'ITC Korinna';
  src: url('./fonts/itc-korinna-bold.otf') format('opentype')
}

/* Board */
@font-face {
  font-family: 'Swiss 911 Extra';
  src: url('./fonts/swiss-911-extra-compr9c22f.ttf') format('truetype')
}
@font-face {
  font-family: 'Swiss 911';
  src: url('./fonts/swiss-911-compressed.otf') format('opentype')
}

/* Scores */
@font-face {
  font-family: 'Univers 75 Black';
  src: url('./fonts/univers-75-black.ttf') format('truetype')
}

.font-itc-korinna { font-family: 'ITC Korinna' }
.font-swiss-911-extra { font-family: 'Swiss 911 Extra' }
.font-swiss-911 { font-family: 'Swiss 911' }
.font-univers-75-black { font-family: 'Univers 75 Black' }

/* Player names */
@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dawning+of+a+New+Day&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Give+You+Glory&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hachi+Maru+Pop&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Handlee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hi+Melody&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kristi&display=swap');
@import url('https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Long+Cang&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nothing+You+Could+Do&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Schoolbell&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Short+Stack&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Butterfly+Kids&display=swap');

.player-font-0 { font-family: 'Caveat'; }
.player-font-1 { font-family: 'Shadows Into Light'; }
.player-font-2 { font-family: 'Cedarville Cursive'; }
.player-font-3 { font-family: 'Amatic SC'; }
.player-font-4 { font-family: 'Architects Daughter'; }
.player-font-5 { font-family: 'Dawning of a New Day'; }
.player-font-6 { font-family: 'Give You Glory'; margin-top: 10px; }
.player-font-7 { font-family: 'Gloria Hallelujah'; }
.player-font-8 { font-family: 'Hachi Maru Pop'; margin-top: -12px; }
.player-font-9 { font-family: 'Handlee'; margin-top: 8px; }
.player-font-10 { font-family: 'Hi Melody'; margin-top: 12px; }
.player-font-11 { font-family: 'Indie Flower'; margin-top: 8px; }
.player-font-12 { font-family: 'Kristi'; margin-top: 2px; }
.player-font-13 { font-family: 'La Belle Aurore'; margin-top: 20px; }
.player-font-14 { font-family: 'Long Cang'; }
.player-font-15 { font-family: 'Nothing You Could Do'; margin-top: 10px; }
.player-font-16 { font-family: 'Reenie Beanie'; margin-top: 5px; }
.player-font-17 { font-family: 'Schoolbell'; margin-top: 5px; }
.player-font-18 { font-family: 'Short Stack'; margin-top: 4px; }
.player-font-19 { font-family: 'Butterfly Kids'; }
